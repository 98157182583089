import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { RequestType } from '../../models'
import { hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import HeadingTwo from '../../shared/UI/HeadingTwo'
import Paragraph from '../../shared/UI/Paragraph'

import { callOutFromContent, globalContent, absenceFormLabels } from '../../utils/constants'
import DateTimePicker from '../../shared/UI/DateTimePicker'
import ManagerEmployeeDropDown from '../MultiDayRequest/ManagerEmployeeDropDown'
import { PayPeriodContent } from '../../shared/UI/PayPeriodContent/PayPeriodContent'
import { StatusChip } from '../../shared/UI/StatusChip/StatusChip'
import { RowRadioGroup } from './RowRadioGroup/RowRadioGroup'
import { ValidaitonErrors, validateFormValues } from './validations'
import {
  calloutInitialState,
  setCalloutDetails,
  setDateRange,
  setRequestType,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { getDurationInFractionalHours, isDurationMultipleOf30minutes } from '../../utils/date-utils'
import { contentState, setCallOutMenuItems } from '../../redux/reducers/contentReducer'
import { enhancementService } from '../../services/enhancementService'
import { PayPeriodResponse } from '../../models/enhancement'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import { CustomWidthTooltip } from '../../shared/UI/CustomToolTip/CustomTooltip'

const enhancementType = RequestType.CALL_OUT_M
export const callOutTestId = 'callout'

export interface CallOutEnhancementPropsBase {
  isViewOnly?: boolean
  isManager?: boolean
  isEmployeeSelected?: boolean
  disabledDates?: string[]
  submitLoading?: boolean
  onViewOnlyDrawerClose?: () => void
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
  handleClose?: (event: React.FormEvent<HTMLFormElement>, submission?: boolean) => void
  payPeriod?: PayPeriodResponse
}
interface ViewOnlyProps extends CallOutEnhancementPropsBase {
  isViewOnly: true
  onViewOnlyDrawerClose: () => void
}

interface EditableProps extends CallOutEnhancementPropsBase {
  isViewOnly?: false
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
}

type CallOutEnhancementProps = ViewOnlyProps | EditableProps

export function CallOutEnhancement({
  isViewOnly,
  isManager,
  disabledDates,
  isEmployeeSelected,
  submitLoading,
  onViewOnlyDrawerClose,
  handleSubmit,
  handleClose,
  payPeriod,
}: CallOutEnhancementProps) {
  const dispatch = useAppDispatch()
  const defaultErrorHandler = useDefaultErrorHandler()
  // States
  const [formErrors, setFormErrors] = useState<ValidaitonErrors>()
  const [duration, setDuration] = useState<number>(0)
  const [displayDuration, setDisplayDuration] = useState<number>(0)
  const { dateRange, calloutDetails } = useSelector<RootStore, timeOffRequestsState>(
    (state: RootStore) => state.timeOff
  )
  const { callOutMenuItems } = useSelector<RootStore, contentState>(
    (state: RootStore) => state.content
  )

  /**
   * Check if the selected duration is multiple of 30 minutes
   */
  const validateAndSetDuration = () => {
    const [start, end] = dateRange
    if (start && end) {
      const hours = getDurationInFractionalHours(start, end)
      const isDurationMultipleof30Minutes = isDurationMultipleOf30minutes(start, end)
      setFormErrors(prevState => ({
        ...prevState,
        duration: !isDurationMultipleof30Minutes,
        negetiveDuration: hours <= 0,
      }))

      setDuration(hours)
      if (hours <= 0) {
        setDisplayDuration(hours)
      } else {
        setDisplayDuration(hours < 2 ? 2 : hours)
      }
    }
  }
  /**
   * Set enhancement request type on component render
   */
  useEffect(() => {
    if (isManager) {
      dispatch(setRequestType(RequestType.CALL_OUT_M))
    } else {
      dispatch(setRequestType(RequestType.CALL_OUT))
    }
  }, [])

  /**
   * Fetch callout menu items if not present in the store
   */
  useEffect(() => {
    if (!callOutMenuItems)
      enhancementService
        .getCallOutMenuItems()
        .then(response => {
          dispatch(setCallOutMenuItems(response))
        })
        .catch(defaultErrorHandler)
  }, [callOutMenuItems])

  /**
   * Revalidate when date range changes
   */
  useEffect(() => {
    validateAndSetDuration()
  }, [dateRange])

  const renderPayPeriod = useMemo(
    () =>
      payPeriod ? (
        <Grid
          item
          xs={12}
          gap={1}
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Typography variant="body2" color="#000000">
            <PayPeriodContent
              payPeriod={payPeriod.currentPeriod.period}
              dateRange={payPeriod.dateRange}
            />
          </Typography>
          <StatusChip
            label={`
              Submit by ${format(
                new Date(isManager ? payPeriod.managerCutOff : payPeriod.employeeCutOff),
                'HH:mm'
              )}
              on ${format(
                new Date(isManager ? payPeriod.managerCutOff : payPeriod.employeeCutOff),
                'do MMMM'
              )}
            `}
            type="pending"
          />
        </Grid>
      ) : null,
    []
  )

  const renderAutocompleteInput = (params: AutocompleteRenderInputParams, label: string) => (
    <TextField
      {...params}
      label={callOutFromContent[label]}
      variant="outlined"
      error={formErrors?.[label]}
      helperText={globalContent.required}
      fullWidth
    />
  )
  
  return (
    <Grid
      component="form"
      noValidate
      container
      columnSpacing={6}
      rowSpacing={{ xs: 3, sm: 4 }}
      data-testid={callOutTestId}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const { isValid, validaitonErrors } = validateFormValues(dateRange, calloutDetails)
        setFormErrors(validaitonErrors)
        validateAndSetDuration()
        if (isValid && !formErrors?.duration && !formErrors?.negetiveDuration && handleSubmit) {
          handleSubmit(event, enhancementType)
        }
      }}
    >
      {isManager && !isViewOnly && (
        <>
          <Grid item xs={12} lg={6}>
            <ManagerEmployeeDropDown />
          </Grid>
          <Grid item xs={12} lg={6}>
            {!isEmployeeSelected && (
              <Alert
                severity="info"
                message={absenceFormLabels.selectEmployee}
                testId="select-employee-alert"
              />
            )}
          </Grid>
        </>
      )}
      {renderPayPeriod}
      {isEmployeeSelected || !isManager ? (
        <>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paragraph weight="bold">{globalContent.details}</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[0]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([date, dateRange[1]]))}
                  label={globalContent.from}
                  error={formErrors?.start}
                  helperText={globalContent.required}
                  minutesStep={5}
                  disabledDates={disabledDates}
                  dataTestId={`${callOutTestId}-from-date`}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[1]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([dateRange[0], date]))}
                  label={globalContent.to}
                  error={formErrors?.end}
                  helperText={globalContent.required}
                  disabledDates={disabledDates}
                  minutesStep={5}
                  dataTestId={`${callOutTestId}-to-date`}
                  disabled={isViewOnly}
                />
                {formErrors?.negetiveDuration ? (
                  <Alert
                    severity="error"
                    message={callOutFromContent.negetiveDuration}
                    testId="negative-duration-alert"
                  />
                ) : null}
                {formErrors?.duration && !formErrors?.negetiveDuration ? (
                  <Alert
                    severity="error"
                    message={callOutFromContent.durationError}
                    testId="invalid-duration-alert"
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.department || []}
                  value={calloutDetails.calledOutBy}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'calledOutBy')}
                  data-testid={`${callOutTestId}-department-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, calledOutBy: value }))
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.issueType || []}
                  value={calloutDetails.issueType}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'issueType')}
                  data-testid={`${callOutTestId}-issueType-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, issueType: value }))
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.escalationRequired}
                label={callOutFromContent.escalated}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, escalationRequired: newValue }))
                }
                tooltipText={callOutFromContent.escalatedToTooltip}
                testid={`${callOutTestId}-radio-escalated`}
                disabled={isViewOnly}
              />
              {calloutDetails.escalationRequired ? (
                <>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalationType || []}
                      value={calloutDetails.escalationType}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalationType')}
                      data-testid={`${callOutTestId}-escalationType-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalationType: value }))
                      }
                      disabled={isViewOnly}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalateTo || []}
                      value={calloutDetails.escalatedTo}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalatedTo')}
                      data-testid={`${callOutTestId}-escalatedTo-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalatedTo: value }))
                      }
                      disabled={isViewOnly}
                    />
                  </Grid>
                </>
              ) : null}
              <RowRadioGroup
                value={calloutDetails.transferToAnotherTeam}
                label={callOutFromContent.transferredToTeam}
                onRadioChange={newValue =>
                  dispatch(
                    setCalloutDetails({ ...calloutDetails, transferToAnotherTeam: newValue })
                  )
                }
                testid={`${callOutTestId}-radio-transferredToTeam`}
                disabled={isViewOnly}
              />
              {calloutDetails.transferToAnotherTeam ? (
                <Grid item xs={12}>
                  <Autocomplete
                    options={callOutMenuItems?.team || []}
                    value={calloutDetails.team}
                    getOptionLabel={option => option}
                    renderInput={params => renderAutocompleteInput(params, 'team')}
                    data-testid={`${callOutTestId}-team-dropdown`}
                    onInputChange={(_e, value) =>
                      dispatch(setCalloutDetails({ ...calloutDetails, team: value }))
                    }
                    disabled={isViewOnly}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Paragraph weight="bold">{globalContent.requestSummary}</Paragraph>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.conclusion || []}
                  value={calloutDetails.conclusion}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'conclusion')}
                  data-testid={`${callOutTestId}-conclusion-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, conclusion: value }))
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={calloutDetails.jiraReference}
                  onChange={e =>
                    dispatch(
                      setCalloutDetails({ ...calloutDetails, jiraReference: e.target.value })
                    )
                  }
                  id="jiraReference"
                  label={callOutFromContent.jiraReference}
                  helperText={globalContent.required}
                  error={formErrors?.jiraReference}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.product || []}
                  value={calloutDetails.product}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'product')}
                  data-testid={`${callOutTestId}-product-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, product: value }))
                  }
                  renderOption={(props, option, { selected, index }) => (
                    <li {...props} key={`${option}-${index}`}>
                      <span>{option}</span>
                    </li>
                  )}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="reason"
                  label={callOutFromContent.reason}
                  fullWidth
                  rows={6}
                  multiline
                  value={calloutDetails.reason}
                  onChange={e =>
                    dispatch(setCalloutDetails({ ...calloutDetails, reason: e.target.value }))
                  }
                  helperText={globalContent.required}
                  error={formErrors?.reason}
                  disabled={isViewOnly}
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.codeChange}
                label={callOutFromContent.codeChangeReleased}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, codeChange: newValue }))
                }
                testid={`${callOutTestId}-radio-codeChange`}
                disabled={isViewOnly}
              />
              <RowRadioGroup
                value={calloutDetails.logInRequired}
                label={callOutFromContent.logInRequired}
                onRadioChange={newValue => {
                  dispatch(setCalloutDetails({ ...calloutDetails, logInRequired: newValue }))
                }}
                testid={`${callOutTestId}-radio-logInRequired`}
                tooltipText={callOutFromContent.loginRequiredTooltip}
                disabled={isViewOnly}
              />

              {duration ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 'auto',
                    flexGrow: 1,
                  }}
                >
                  <HeadingTwo title={globalContent.duration} />
                  <Box display="flex" alignItems="center">
                    <HeadingTwo title={`${displayDuration.toFixed(2)} Hours`} />
                    <CustomWidthTooltip
                      title={callOutFromContent.adjutedHoursTooltip}
                      data-testid="adjusted-hours-tooltip"
                    >
                      <Info fontSize="small" sx={{ color: '#20C5A0', marginLeft: '4px' }} />
                    </CustomWidthTooltip>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}
      {isViewOnly && (
        <DrawerFooter>
          <Button
            color="secondary"
            label="Close"
            onClick={() => {
              dispatch(setCalloutDetails(calloutInitialState))
              dispatch(setDateRange([null, null]))
              onViewOnlyDrawerClose()
            }}
          />
        </DrawerFooter>
      )}
      {!isViewOnly && (isEmployeeSelected || !isManager) ? (
        <DrawerFooter>
          <Button
            color="secondary"
            label="Cancel"
            onClick={event => {
              dispatch(hideHeaderDrawer())
              dispatch(setCalloutDetails(calloutInitialState))
              dispatch(setDateRange([null, null]))
              handleClose?.(event, false)
            }}
            style={{ ml: 4 }}
          />
          <Button
            label="Submit"
            type="submit"
            loading={submitLoading}
            disabled={formErrors?.duration || formErrors?.negetiveDuration}
          />
        </DrawerFooter>
      ) : null}
    </Grid>
  )
}
